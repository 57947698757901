.item {
    display: inline-flex;
    padding-right: 5px;
}

.dataView {
    width: fit-content;
    padding-left: 10px;
}

.infoDiv {
    display: inline-flex;
    padding-top: 10px;
    padding-bottom: 10px;
}

.maps {
    width: 450px;
    height: 450px;
}